import styled from "styled-components";
import chevronRight from '../../img/chevron_right.svg';


const CustomerCaseCard = ({ img, title, subCopy, firstChip, secondChip, isSmallScreen, lastCardRef, handleOpenModal, index }) => {

    return (
        <Card isSmallScreen={isSmallScreen} ref={lastCardRef}>
            <ImgContainer isSmallScreen={isSmallScreen}>
                <img src={img} />
            </ImgContainer>
            <TextContent isSmallScreen={isSmallScreen}>
                <Title isSmallScreen={isSmallScreen}>
                    {title}
                </Title>
                <SubCopy isSmallScreen={isSmallScreen}>
                    {subCopy}
                </SubCopy>
                <div className="flex justify-between items-center">
                    <ChipContainer isSmallScreen={isSmallScreen}>
                        <Chip isSmallScreen>{firstChip}</Chip>
                        <Chip isSmallScreen>{secondChip}</Chip>
                    </ChipContainer>
                    <DetailView onClick={() => handleOpenModal(index)} isSmallScreen={isSmallScreen}>
                        <span>자세히 보기</span>
                        <img src={chevronRight} />
                    </DetailView>
                </div>
            </TextContent>
        </Card>
    );

}

export default CustomerCaseCard;


const Card = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '380px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '447px' : '536px'};
    padding-bottom: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '26px'};

    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
`;

const ImgContainer = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '380px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '380px'};

    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    overflow: hidden;
`;

const TextContent = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '293px' : '344px'};
    margin: ${({ isSmallScreen }) => isSmallScreen ? '20px auto 0 auto' : '27px auto 0 auto'};
`;

const Title = styled.div`
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '20px'};
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.3px;

    margin-bottom: 4px;
`;

const SubCopy = styled.div`
    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.24px;

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '12px'};
`;

const ChipContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 8px;
`;

const Chip = styled.div`
    padding: ${({ isSmallScreen }) => isSmallScreen ? '4px 10px' : '6px 12px'};
    background-color: #EBEBEB;

    border-radius: 24px;

    color: ${({ theme }) => theme.colors.gray3};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '14px'};
    line-height: 150%;
    letter-spacing: -0.21px;
`;

const DetailView = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '14px'};
    line-height: 150%;
    color: #4F4F4F;
    text-align: right;
    line-height: 150%;
    letter-spacing: -0.21px;

    display: inline-flex;
    gap: 5px;

    cursor: pointer;
`; 