import styled from "styled-components";
import { theme } from "../../utils/palette";
import Content from "./Content";
import ExtractSmallData from "./ExtractSmallData";
import SmallDataAdmin from "./SmallDataAdmin";
import useScrollVisible from "../../hooks/useScrollVisible";
import { opacityAnimation, OpacityAnimation, refloatAnimation } from "../../styled/common";
import React, { useRef } from "react";
import useScreenSize from "../../hooks/useScreenSize";


const SaltiProcess = React.forwardRef((props, ref) => {

    const { isSmallScreen } = useScreenSize();

    const titleRef = useRef(null);
    const sectionRef1 = useRef(null);
    const sectionRef2 = useRef(null);
    const [ isTitleVisible ] = useScrollVisible(titleRef, 0, 0);
    const [ isVisible1 ] = useScrollVisible(sectionRef1, 1, 0);
    const [ isVisible2 ] = useScrollVisible(sectionRef2, 1, 0);

    const firstTitle = `기업의 고객을 대상으로 스몰데이터 추출`;
    const firstSubCopy = `Web / App Add-on 방식`;
    const secondTitle = `고객의 스몰데이터를 관리할 수 있는 Admin`;
    const secondSubCopy = `대시보드 및 분석등의 기능을 통해 인사이트 확인 가능`;

    
    return (
        <Section isSmallScreen={isSmallScreen} ref={ref}>
            <Title 
                isVisible={isTitleVisible} 
                ref={titleRef}
                isSmallScreen={isSmallScreen}
                >
                    SALTi 프로세스
            </Title>
            <OpacityAnimation isVisible={isVisible1} ref={sectionRef1}>
                <Content 
                    title={firstTitle}
                    subCopy={firstSubCopy}
                    isSmallScreen={isSmallScreen}
                />
            </OpacityAnimation>
            <ExtractSmallData isSmallScreen={isSmallScreen} />
            <OpacityAnimation isVisible={isVisible2} ref={sectionRef2}>
                <Content 
                    title={secondTitle}
                    subCopy={secondSubCopy}
                    isSmallScreen={isSmallScreen}
                />
            </OpacityAnimation>
            <SmallDataAdmin />
        </Section>
    );

})

export default SaltiProcess;


const Section = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '780px' };
    margin: 0 auto;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '80px 0' : '160px 0'};

    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '28px' };
    font-weight: ${({ isSmallScreen }) => isSmallScreen ? '600' : '700' };
    color: ${({ theme }) => theme.colors.gray4 };

    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '60px' : '100px' };

    align-self: center;

    ${opacityAnimation};
`;