import { useRef, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import { membersData } from "../../datas/membersData";
import MemberCard from "./MemberCard";
import styled from "styled-components";
import useScrollVisible from "../../hooks/useScrollVisible";
import { OpacityAnimation, RefloatAnimation } from "../../styled/common";

const Members = () => {

    const { isSmallScreen } = useScreenSize();
    const [ memebers ] = useState(membersData);

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0.5, 850);
    

    return (
        <section ref={sectionRef}>
            <RefloatAnimation isVisible={isFristVisible}>
                <Content 
                    title={'OUR MEMBERS'}
                    color={theme.colors.gray4}
                    isSmallScreen={isSmallScreen}
                />
            </RefloatAnimation>
            <OpacityAnimation isVisible={isSecondVisible} className="w-screen overflow-auto">
                <MemberList isSmallScreen={isSmallScreen}>
                {
                    memebers.map((member, index) => 
                        <MemberCard 
                            member={member}
                            isSmallScreen={isSmallScreen}
                            key={index}
                        />
                    )
                }
                </MemberList>
            </OpacityAnimation>
        </section>
    );

}

export default Members;


const MemberList = styled.section`
    width: ${({ isSmallScreen }) => isSmallScreen ? '1072px' : '3888px'};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 20px' : '20px 50px'};

    display: flex;
    flex-wrap: wrap;
    column-gap: ${({ isSmallScreen }) => isSmallScreen ? '8px' : '32px'};
    row-gap: ${({ isSmallScreen }) => isSmallScreen ? '24px' : ''};
`;