import styled from 'styled-components';
import clip from '../../img/icon/clip.svg';
import x from '../../img/icon/x.svg';
import { useState } from 'react';


const FileInput = ({ handleFileChange, isSmallScreen }) => {

    const [ fileInfo, setFileInfo ] = useState([]);
    const allowedExtensions = ['ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'doc', 'docx', 'hwp', 'jpeg', 'jpg', 'png', 'webp', 'zip'];

    const onFileChange = (e) => {
        const files = Array.from(e.target.files);

        const filteredFiles = files.filter(file => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            return allowedExtensions.includes(fileExtension);
        });

        if (filteredFiles.length < files.length) {
            alert('허용되지 않은 파일이 있습니다. 지원되는 파일 형식만 업로드 해주세요.');
        }

        const fileDetails = filteredFiles.slice(0, 3).map((file) => ({
            name: file.name,
            size: (file.size / (1024 * 1024)).toFixed(2),
            url: URL.createObjectURL(file)
        }));

        setFileInfo(fileDetails);
        handleFileChange(e);
    };

    const deleteFile = (e, index) => {
        e.preventDefault();
        const updatedFiles = fileInfo.filter((_, i) => i !== index);
        setFileInfo(updatedFiles);
    }


    return (
        <FileSection isSmallScreen={isSmallScreen} fileInfo={fileInfo}>
        {
            fileInfo.length > 0 && (
                <FileList isSmallScreen={isSmallScreen}>
                {
                    fileInfo.map((file, index) => (
                        <FileInfo key={index} isSmallScreen={isSmallScreen}>
                            <a 
                                href={file.url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className={`flex ${isSmallScreen ? 'gap-x-4' : 'gap-x-8'} items-center`}
                                >
                                <FileName isSmallScreen={isSmallScreen}>
                                    { file.name } 
                                </FileName>
                                <span className={`${isSmallScreen ? 'text-7' : 'text-11'}`}>
                                    ( {file.size} MB )
                                </span>
                            </a>
                            <button 
                                className={`${isSmallScreen ? 'w-13 h-13' : 'w-17 h-17'}`}
                                onClick={(e) => deleteFile(e, index)}
                                >
                                <img src={x} className="block w-full h-full" />
                            </button>
                        </FileInfo>
                    ))
                }
                </FileList>
            )
        }
            <Label isSmallScreen={isSmallScreen}>
                <img src={clip} />
                <span>파일 첨부</span>
                <input type="file" className="hidden" onChange={onFileChange} multiple />
            </Label>
        </FileSection>
    );

}

export default FileInput;


const FileSection = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '580px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '42px' : '68px'};
    margin-top: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '16px'};

    display: flex;
    justify-content: ${({ fileInfo }) => fileInfo.length == 0 ? 'flex-end' : 'space-between'};
    align-self: flex-end;
`;

const Label = styled.label`
    width: ${({ isSmallScreen }) => isSmallScreen ? '120px' : '160px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '40px' : '44px'};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid ${({ theme }) => theme.colors.main1};
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '8px'};

    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray3};

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '6px' : '16px'};

    cursor: pointer;

    &:hover {
        background-color: rgba(254, 254, 254, 0.75);
    }
`;

const FileList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => (isSmallScreen ? '2px' : '4px')};
    width: ${({ isSmallScreen }) => (isSmallScreen ? '180px' : '350px')};
`;

const FileInfo = styled.div`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '12px'};
    color: ${({ theme }) => theme.colors.gray3};
    width: inherit;
    height: ${({ isSmallScreen }) => isSmallScreen ? '13px' : '20px'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};

    a {
        color: ${({ theme }) => theme.colors.gray3};

        &:hover {
            color: ${({ theme }) => theme.colors.accent3};
        }
    }
`;

const FileName = styled.span`
    display: inline-block;
    max-width: ${({ isSmallScreen }) => isSmallScreen ? '110px' : '280px'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
`;