import Content from "../common/Content.js";
import CustomerInsightTable from "./CustomerInsightTable.js";
import Chips from "../common/Chips.js";
import { Section, RefloatAnimation, OpacityAnimation } from "../../styled/common.js";
import { theme } from "../../utils/palette.js";
import { chipDataMain, krMain } from "../../datas/data.js";
import useScrollVisible from "../../hooks/useScrollVisible.js";
import { useMultiSyncAnimation } from "../../hooks/useMultiSyncAnimation.js";
import { useRef } from "react";
import useScreenSize from "../../hooks/useScreenSize.js";


const CustomerInsight = ({ height }) => {

    const { isSmallScreen } = useScreenSize();

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0.5, 850);

    const isVisible = useMultiSyncAnimation(1, isSecondVisible, 500);

    const title = isSmallScreen ? krMain.insight.title.m :  krMain.insight.title.pc;
    const subCopy = isSmallScreen ? krMain.insight.subCopy.m : krMain.insight.subCopy.pc;
    const color = theme.colors.gray3;

    const chipsWidth = isSmallScreen ? '360px' : '980px';
    const chipsBgColor = theme.colors.main1;
    const chipsColor = theme.colors.white;
    const chipsFontSize = isSmallScreen ? '12px' : '14px';
    const top = isSmallScreen ? '-130px' : '-250px';


    return (
        <Section className="flex-col" height={height} ref={sectionRef}>
            <section className={`${isSmallScreen ? 'w-320 h-350' : 'w-780 h-562'} mx-auto z-10`}>
                <RefloatAnimation isVisible={isFristVisible}>
                    <Content 
                        title={title}
                        subCopy={subCopy}
                        color={color}
                        isSmallScreen={isSmallScreen}
                    />
                </RefloatAnimation>
                <OpacityAnimation isVisible={isVisible[0]}>
                    <CustomerInsightTable isVisible={isVisible[0]} isSmallScreen={isSmallScreen} />
                </OpacityAnimation>
            </section>
            <OpacityAnimation isVisible={isSecondVisible}>
                <Chips
                    chipsData={chipDataMain}
                    width={chipsWidth}
                    bgColor={chipsBgColor}
                    color={chipsColor}
                    fontSize={chipsFontSize}
                    top={top}
                    isSmallScreen={isSmallScreen}
                />
            </OpacityAnimation>
        </Section>
    );

}

export default CustomerInsight;