import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import Card from "./Card";
import { pressReleaseData } from "../../datas/pressReleaseData";
import { useRef, useState } from "react";
import useScrollVisible from "../../hooks/useScrollVisible";
import { OpacityAnimation, refloat, RefloatAnimation } from "../../styled/common";


const PressRelease = () => {

    const { isSmallScreen } = useScreenSize();
    const [ pressReleaseDatas ] = useState(pressReleaseData);

    const sectionRef = useRef(null);
    const threshold = isSmallScreen ? 1 : 0.5;
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, threshold, 850);


    return (
        <Section isSmallScreen={isSmallScreen} isVisible={isFristVisible} ref={sectionRef}>
            <RefloatAnimation isVisible={isFristVisible}>
                <H1 isSmallScreen={isSmallScreen}>보도자료</H1>
            </RefloatAnimation>
            <OpacityAnimation isVisible={isSecondVisible}>
                <CardList isSmallScreen={isSmallScreen}>
                {
                    pressReleaseDatas.map((pressRelease, index) => 
                        <Card 
                            pressRelease={pressRelease}
                            isSmallScreen={isSmallScreen} 
                            key={index}
                        />
                    )
                }
                </CardList>
            </OpacityAnimation>
        </Section>
    );

}

export default PressRelease;


const Section = styled.section`
    background-color: ${({ theme }) => theme.colors.main3};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '40px 0' : '80px 0'};

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    transition: all 0.7s ease;
    animation: ${({ isVisible }) => (isVisible ? refloat : 'none')} 0.85s ease-in-out forwards;
`;

const H1 = styled.h1`
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '36px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '20px' : '28px'};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.gray4};
    text-align: center;
    line-height: 140%;
`;

const CardList = styled.div`
    padding: ${({ isSmallScreen }) => isSmallScreen ? '0 25px' : '0 50px'};
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '20px'};
    white-space: nowrap;
`;