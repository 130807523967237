import styled from 'styled-components';
import bgImage from '../../img/solution_main_bg.png';
import saltiWhite from '../../img/SALTi_white.svg';
import { OpacityAnimation, refloatAnimation, Section } from '../../styled/common.js';
import useScreenSize from '../../hooks/useScreenSize';
import useScrollVisible from '../../hooks/useScrollVisible';
import { chipDataSolution } from '../../datas/data.js';
import { theme } from '../../utils/palette.js';
import Chips from '../common/Chips.js';
import { useRef } from 'react';
import { scrollToSection } from '../../utils/functions.js';
import ScrollYButton from '../common/ScrollYButton.js';


const SmallDataValueBg = ({ height, nextSectionRef, headerHeight }) => {

    const { isSmallScreen } = useScreenSize();

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0, 850);

    const handleScroll = () => { scrollToSection(nextSectionRef, headerHeight); };

    const bgWidth = '100%';
    const bgHeight = height;

    const contentBoxMargin = '29vh auto 0 auto';

    const chipsWidth = isSmallScreen ? '335px' : '750px';
    const chipsFontSize = isSmallScreen ? '8px' : '18px';
    const chipPadding = isSmallScreen ? '4px 6px' : '';
    const rowGap = isSmallScreen ? '8px' : '16px';
    const columnGap = isSmallScreen ? '6.4px' : '16px';
    const chipsBgColor = theme.colors.white;
    const chipsColor = theme.colors.black;
    const top = '0';
    
    
    return (
        <Section ref={sectionRef}>
            <BgCrop height={bgHeight}>
                <img src={bgImage} className="object-cover"/>
                <Background 
                    width={bgWidth}
                    height={bgHeight}
                />
                <ContentBox margin={contentBoxMargin} isVisible={isFristVisible}>
                    <Logo isSmallScreen={isSmallScreen}>
                        <img src={saltiWhite} className="block w-full h-full"/>
                    </Logo>
                    <H1 isSmallScreen={isSmallScreen}>
                        고객을 이해하는 가장 쉬운 방법<br/>
                        스몰데이터 솔루션
                    </H1>
                    <OpacityAnimation isVisible={isSecondVisible}>
                        <Chips
                            chipsData={chipDataSolution}
                            width={chipsWidth}
                            bgColor={chipsBgColor}
                            color={chipsColor}
                            fontSize={chipsFontSize}
                            top={top}
                            isSmallScreen={isSmallScreen}
                            rowGap={rowGap}
                            columnGap={columnGap}
                            padding={chipPadding}
                        />
                    </OpacityAnimation>
                </ContentBox>
                <ScrollYButton onClick={handleScroll} isVisible={isSecondVisible}/>
            </BgCrop>
        </Section>
    );

}

export default SmallDataValueBg;
const H1 = styled.h1`
    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '24px' : '40px' };
    font-weight: 700;
    margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '32px' };
`;

const BgCrop = styled.div`
    width: 100%;
    height: ${ ({height}) => height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const Background = styled.section`
    width: ${ ({width}) => width };
    height: ${ ({height}) => height };
    opacity: 0.7;
    background: linear-gradient(107deg, rgba(27,47,163,1), rgba(0,129,218,1), rgba(0,180,168,1));
    background-size: cover;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
`;

const ContentBox = styled.div`
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    margin: ${ ({margin}) => margin };
    
    position: absolute;

    ${refloatAnimation};
`;

const Logo = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '80px' : '120px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '24px' : '36px' };
    margin: ${ ({isSmallScreen}) => isSmallScreen ? '0 auto 24px auto' : '0 auto 16px auto' };
`;