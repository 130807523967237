import styled from "styled-components";
import Required from "./Required";


const RadioInput = ({ formData, handleChange, isSmallScreen }) => {

    return (
        <div className={`flex ${isSmallScreen ? 'flex-col gap-y-8' : 'flex-row justify-between'}`}>
            <div>
                <span className={`${isSmallScreen ? 'text-16 font-bold' : 'text-20 font-semibold'} text-gray3`}>
                    문의 유형
                </span>
                <Required>*</Required>
            </div>
            <div className={`flex ${isSmallScreen ? 'gap-x-16' : 'gap-x-28 w-580'}`}>
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="inquiryType"
                        value="service"
                        checked={formData.inquiryType === "service"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    서비스 도입
                </Label>
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="inquiryType"
                        value="partnership"
                        checked={formData.inquiryType === "partnership"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    제휴 및 제안
                </Label>
                <Label isSmallScreen={isSmallScreen}>
                    <CustomRadio
                        type="radio"
                        name="inquiryType"
                        value="other"
                        checked={formData.inquiryType === "other"}
                        onChange={handleChange}
                        isSmallScreen={isSmallScreen}
                    />
                    기타
                </Label>
            </div>
        </div>
    );

}

export default RadioInput;


const Label = styled.label`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray3};
    line-height: 30px;

    display: inline-flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;
`;

const CustomRadio = styled.input.attrs({ type: 'radio' })`
    appearance: none;
    width: ${({isSmallScreen }) => isSmallScreen ? '20px' : '22px'};
    height: ${({isSmallScreen }) => isSmallScreen ? '20px' : '22px'};
    border: 2px solid #7E7E7E;
    border-radius: 18px;
    cursor: pointer;

    &:checked {
        border: ${({ isSmallScreen, theme }) =>  isSmallScreen ? 
                `4px solid ${theme.colors.main1}` : 
                `6px solid ${theme.colors.main1}`};
`;