import { useState, useEffect } from "react";


export const useMultiSyncAnimation = (num, state, delaytime) => {

    const isVisibleArray = new Array(num);
    const [ isVisible, setIsVisible ] = useState(
        isVisibleArray.fill(false)
    );

    useEffect(() => {
        if (state) {
            isVisible.forEach((_, index) => {
                setTimeout(() => {
                    setIsVisible(prev => {
                        const newStates = [...prev];
                        newStates[index] = true;
                        return newStates;
                    });
                }, (index + 1) * delaytime + 500);
            });
        }
    }, [state]);

    return isVisible;
}