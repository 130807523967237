import detailedInfoImg from '../../img/detailed_info.svg';
import personalDataImg from '../../img/personal_data.svg';
import customerBehaviorImg from '../../img/customer_behavior.svg';
import accuracySpeedImg from '../../img/accuracy_speed.svg';
import styled from 'styled-components';
import { RefloatAnimation, OpacityAnimation, Section } from '../../styled/common.js';
import useScrollVisible from '../../hooks/useScrollVisible';
import { krMain } from '../../datas/data.js';
import React, { useEffect, useRef } from 'react';
import useScreenSize from '../../hooks/useScreenSize.js';


const SmallData = React.forwardRef(({ height }, ref) => {

    const { isSmallScreen } = useScreenSize();

    const [ isFristVisible, isSecondVisible ] = useScrollVisible(ref, 0.5, 850);
    
    const title = isSmallScreen ? krMain.smData.title.m : krMain.smData.title.pc;
    const subCopy = isSmallScreen? krMain.smData.subCopy.m : krMain.smData.subCopy.pc;

    const contents = [
        {
            img: detailedInfoImg,
            text1: '빅데이터보다',
            text2: '세세한 수집 정보'
        },
        {
            img: personalDataImg,
            text1: '전체 데이터 분석이 아닌',
            text2: '개인을 알 수 있는 데이터'
        },
        {
            img: customerBehaviorImg,
            text1: '유추 가능한',
            text2: '고객 행동'
        },
        {
            img: accuracySpeedImg,
            text1: '상대적으로 높은',
            text2: '정확성과 속도'
        }
    ];

    
    return (
        <Section className="bg-white" height={height} ref={ref}>
            <section className={`flex flex-col ${isSmallScreen ? 'w-320 gap-y-24' : 'w-780 gap-y-80'} mx-auto`}>
                <RefloatAnimation 
                    className={`flex ${isSmallScreen ? 'flex-col' : 'flex-row'} gap-x-20 gap-y-8`} 
                    isVisible={isFristVisible}>
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }}
                    />
                </RefloatAnimation>
                <OpacityAnimation className={`flex flex-wrap ${isSmallScreen ? 'gap-8' : 'gap-20'}`} isVisible={isSecondVisible}>
                    {
                        contents.map((content, i) => (
                            <Container isSmallScreen={isSmallScreen} key={i}>
                                <ImageBox>
                                    <img src={content.img}/>
                                </ImageBox>
                                <TextBox className="text-center" isSmallScreen={isSmallScreen}>
                                    <span>{content.text1}</span><br/>
                                    <span className="font-semibold">{content.text2}</span>
                                </TextBox>
                            </Container>
                        ))
                    }
                </OpacityAnimation>
            </section>
        </Section>
    );

})

export default SmallData;


const H1 = styled.h1`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '320px' : '180px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '20px' : '24px' };
    font-weight: 600;
    color: ${ ({theme}) => theme.colors.gray3};
`;

const P = styled.p`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '320px' : '580px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
    color: ${ ({theme}) => theme.colors.gray3};
    letter-spacing: ${ ({isSmallScreen}) => isSmallScreen ? '-0.27px' : '-0.21px' };
`;

const Container = styled.div`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '156px' : '180px' };
    height: ${ ({isSmallScreen}) => isSmallScreen ? '156px' : '170px' };
    background-color: #E6F0FF;
    padding: 20px 12px;

    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const ImageBox = styled.div`
    width: 72px;
    height: 72px;
    margin-bottom: 16px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextBox = styled.p`
    width: ${ ({isSmallScreen}) => isSmallScreen ? '135px' : '156px' };

    font-size: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '16px' };
    color: #2F2F2F;
    text-align: center;
    line-height: 160%;
    letter-spacing: -0.24px;
`;