import styled, { keyframes } from 'styled-components';
import bgImage from '../../img/small_data_value_image.png';
import { Link } from 'react-router-dom';
import { Section, opacityAnimation, refloat, refloatAnimation } from '../../styled/common.js';
import useScrollVisible from '../../hooks/useScrollVisible';
import { krMain } from '../../datas/data.js';
import { useRef } from 'react';
import useScreenSize from '../../hooks/useScreenSize.js';
import { scrollToSection } from '../../utils/functions.js';
import ScrollYButton from '../common/ScrollYButton.js';


const SmallDataValueBg = ({ height, nextSectionRef, headerHeight }) => {

    const { isSmallScreen } = useScreenSize();

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0, 850);

    const handleScroll = () => { scrollToSection(nextSectionRef, headerHeight); };

    const title = isSmallScreen ? krMain.smDataBg.title.m : krMain.smDataBg.title.pc;
    const subCopy = isSmallScreen ? krMain.smDataBg.subCopy.m : krMain.smDataBg.subCopy.pc;
    const bgWidth = '100%';
    const textBoxMargin ='35vh auto 0 auto';

    
    return (
        <Section ref={sectionRef}>
            <BgCrop height={height}>
                <img src={bgImage} className="object-cover"/>
                <Background 
                    width={bgWidth}
                    height={height}
                />
                <TextBox margin={textBoxMargin} isVisible={isFristVisible}>
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }} 
                    />
                <LinkButton isVisible={isSecondVisible}>
                    <Link 
                        to="/solution" 
                        className={`${isSmallScreen ? 'text-16' : 'text-18'} font-semibold text-gray3 bg-white px-20 py-12 rounded-48`}
                        >
                            SALTi 알아보기
                    </Link>
                </LinkButton>
                </TextBox>
                <ScrollYButton onClick={handleScroll} isVisible={isSecondVisible} />
            </BgCrop>
            
        </Section>
    );

}

export default SmallDataValueBg;


const BgCrop = styled.div`
    width: 100%;
    height: ${ ({height}) => height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const Background = styled.section`
    width: ${ ({width}) => width };
    height: ${ ({height}) => height };
    opacity: 0.7;
    background: linear-gradient(107deg, rgba(27,47,163,1), rgba(0,129,218,1), rgba(0,180,168,1));
    background-size: cover;
    box-sizing: border-box;

    position: absolute;
    top: 0;
    left: 0;
`;

const TextBox = styled.div`
    width: 468px;
    height: 199px;

    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    margin: ${ ({margin}) => margin };
    
    position: absolute;

    opacity: ${ ({isVisible} ) => isVisible ? 1 : 0 };
    visibility: ${ ({isVisible} ) => isVisible ? 'visible' : 'hidden' };
    animation: ${ ({isVisible} ) => isVisible ? refloat : 'none' } 0.7s ease-in-out forwards;
`;

const H1 = styled.h1`
        font-size: ${ ({isSmallScreen}) => isSmallScreen ? '26px' : '40px' };
        font-weight: 700;
        line-height: 140%;

        margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '14px' : '18px' };
`;

const P = styled.p`
        font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '18px' };
        line-height: 140%;
        letter-spacing: -0.24px;

        margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '32px' : '40px' };
`;


const LinkButton = styled.div`
    ${opacityAnimation};
`;
