import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header, Footer, ScrollToTop } from './components';
import { MainPage, SolutionPage, InfoPage, InquiryPage } from './routes';
import { ThemeProvider } from 'styled-components';
import { theme } from './utils/palette';
import useHeaderHeight from './hooks/useHeaderHeight';


function App() {

  const { height, headerHeight, headerRef } = useHeaderHeight();


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Router>
          <ScrollToTop />
          <Header ref={headerRef} />

          <Routes>
            <Route path='/' element={<MainPage height={height} headerHeight={headerHeight} />} />
            <Route path='/solution' element={<SolutionPage height={height} headerHeight={headerHeight} />} />
            <Route path='/info' element={<InfoPage height={height} headerHeight={headerHeight} />} />
            <Route path='/inquiry' element={<InquiryPage height={height} headerHeight={headerHeight} />} />
          </Routes>

          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );

}

export default App;
