import { useLocation } from "react-router-dom";
import { SaltiProcess, SolutionMainBg, CustomerCase } from "../components";
import { useEffect, useRef } from "react";


const SolutionPage = ({ height, headerHeight }) => {

    const saltiProcessRef = useRef(null);
    const customerCaseRef = useRef(null);

    const location = useLocation();

    useEffect(() => {
        if (location.state?.scrollToCustomerCase) {
            customerCaseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

    
    return (
        <div style={{marginTop : headerHeight}}>
            <SolutionMainBg height={height} nextSectionRef={saltiProcessRef} headerHeight={headerHeight} />
            <SaltiProcess ref={saltiProcessRef} />
            <CustomerCase ref={customerCaseRef} />
        </div>
    );

}

export default SolutionPage;