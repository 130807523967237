import styled from "styled-components";
import subjectImg from '../../img/solution_subject.svg';
import qnaImg1 from '../../img/solution_qna_1.svg';
import qnaImg2 from '../../img/solution_qna_2.svg';
import resultImg1 from '../../img/solution_result_1.svg';
import resultImg2 from '../../img/solution_result_2.svg';
import serviceImg from '../../img/solution_service.svg';
import eventImg from '../../img/solution_event.svg';
import infoImg from '../../img/solution_info.svg';
import { krSmallData } from "../../datas/data";
import useScrollVisible from "../../hooks/useScrollVisible";
import { opacityAnimation } from "../../styled/common";
import { useEffect, useRef, useState } from "react";


const ExtractSmallData = ({ isSmallScreen }) => {

    const thredhold = 1;

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
    const contentRef4 = useRef(null);
    const contentRef5 = useRef(null);
    const contentRef6 = useRef(null);

    const [ isVisible0, setIsVisible0 ] = useState(false);
    const [ isVisibleTop, setIsVisibleTop ] = useState(false);
    const [ isVisibleBottom, setIsVisibleBottom ] = useState(false);


    const topObserver = useScrollVisible(contentRef1, 0, 0);
    const bottomObserver = useScrollVisible(contentRef6, 1, 0);

    useEffect(() => {
        if (topObserver[0] && !isVisibleBottom) {
            setIsVisible0(true);
            setIsVisibleTop(true);
        } else if (bottomObserver[0] && !isVisibleTop) {
            setIsVisible0(true);
            setIsVisibleBottom(true);
        }
    }, [topObserver, bottomObserver, isVisible0, isVisibleTop, isVisibleBottom]);
    
    const [ isVisible1 ] = useScrollVisible(contentRef1, thredhold, 0);
    const [ isVisible2 ] = useScrollVisible(contentRef2, thredhold, 0);
    const [ isVisible3 ] = useScrollVisible(contentRef3, thredhold, 0);
    const [ isVisible4 ] = useScrollVisible(contentRef4, thredhold, 0);
    const [ isVisible5 ] = useScrollVisible(contentRef5, thredhold, 0);
    const [ isVisible6 ] = useScrollVisible(contentRef6, thredhold, 0);


    return (
        <Section isSmallScreen={isSmallScreen}>

            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef1}>
                <ImgContainer isVisible={isVisible1} ref={contentRef1}>
                    <img 
                        src={subjectImg} 
                        className={`absolute block ${isSmallScreen ? 'w-83' : 'w-205'}`} 
                        style={{
                            top : isSmallScreen ? '18px' : '44px',
                            left : isSmallScreen ? '28px' : '89px'
                        }}
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.subject.title.m : krSmallData.subject.title.pc}
                    subCopy={isSmallScreen ? krSmallData.subject.subCopy.m : krSmallData.subject.subCopy.pc}
                    top={`${isSmallScreen ? '36.5px' : '138px'}`}
                    isVisible={isVisible1}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef2}>
                <ImgContainer isVisible={isVisible2}>
                    <img 
                        src={qnaImg1} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-89' : 'w-180 h-215'}`}
                        style={{
                            top : isSmallScreen ? '14.5px' : '35px', 
                            left : isSmallScreen ? '11px' : '27px'
                        }}
                    />
                    <img 
                        src={qnaImg2} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-33' : 'w-180 h-80'}`}
                        style={{
                            top : isSmallScreen ? '79px' : '192px', 
                            left : isSmallScreen ? '51px' : '154px'
                        }}
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.qna.title.m : krSmallData.qna.title.pc}
                    subCopy={isSmallScreen ? krSmallData.qna.subCopy.m : krSmallData.qna.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '135px'}`}
                    isVisible={isVisible2}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef3}>
                <ImgContainer isVisible={isVisible3}>
                    <img 
                        src={resultImg1} 
                        className={`absolute block ${isSmallScreen ? 'w-64 h-87' : 'w-180 h-210'}`}
                        style={{
                            top : isSmallScreen ? '16px' : '35px', 
                            left : isSmallScreen ? '9px' : '27px',
                            zIndex : '5'
                        }}
                    />
                    <img 
                        src={resultImg2} 
                        className={`absolute block ${isSmallScreen ? 'w-64 h-87' : 'w-160 h-220'}`}
                        style={{
                            top : isSmallScreen ? '26px' : '68px', 
                            left : isSmallScreen ? '60px' : '166px'
                        }} 
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.result.title.m : krSmallData.result.title.pc}
                    subCopy={isSmallScreen ? krSmallData.result.subCopy.m : krSmallData.result.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '135px'}`}
                    isVisible={isVisible3}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef4}>
                <ImgContainer isVisible={isVisible4}>
                    <img 
                        src={serviceImg} 
                        className={`absolute block ${isSmallScreen ? 'w-92' : 'w-202 h-277'}`}
                        style={{
                            top : isSmallScreen ? '17px' : '41px', 
                            left : isSmallScreen ? '23px' : '89px'
                        }}
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.service.title.m : krSmallData.service.title.pc}
                    subCopy={isSmallScreen ? krSmallData.service.subCopy.m : krSmallData.service.subCopy.pc}
                    top={`${isSmallScreen ? '37px' : '119px'}`}
                    isVisible={isVisible4}
                    isSmallScreen={isSmallScreen}
                />
            </Container>

            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef5}>
                <ImgContainer isVisible={isVisible5}>
                    <img 
                        src={eventImg} 
                        className={`absolute block ${isSmallScreen ? 'w-90 h-116' : 'w-202 h-284'}`}
                        style={{
                            top : isSmallScreen ? '14px' : '34px', 
                            left : isSmallScreen ? '25.5px' : '89px'
                        }}
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.event.title.m : krSmallData.event.title.pc}
                    subCopy={isSmallScreen ? krSmallData.event.subCopy.m : krSmallData.event.subCopy.pc}
                    top={`${isSmallScreen ? '29px' : '119px'}`}
                    isVisible={isVisible5}
                    isSmallScreen={isSmallScreen}
                />
            </Container>
            
            <Container isVisible={isVisible0} isSmallScreen={isSmallScreen} ref={contentRef6}>
                <ImgContainer isVisible={isVisible6}>
                    <img 
                        src={infoImg} 
                        className={`absolute block ${isSmallScreen ? 'w-74 h-129' : 'w-180 h-299'}`}
                        style={{
                            top : isSmallScreen ? '1px' : '9px', 
                            left : isSmallScreen ? '33px' : '100px'
                        }}
                    />
                </ImgContainer>
                <Content 
                    title={isSmallScreen ? krSmallData.info.title.m : krSmallData.info.title.pc}
                    subCopy={isSmallScreen ? krSmallData.info.subCopy.m : krSmallData.info.subCopy.pc}
                    top={`${isSmallScreen ? '44.5px' : '119px'}`}
                    isVisible={isVisible6}
                    isSmallScreen={isSmallScreen}
                />
            </Container>
            
        </Section>
    );

}

export default ExtractSmallData;


const Section = styled.section`
    width: 100%;
    margin-bottom: ${({ isSmallScreen }) => isSmallScreen ? '50px' : '100px' };

    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '32px' };
`;

const Container = styled.div`
    width: 100%;
    height: ${({ isSmallScreen }) => isSmallScreen ? '130px' : '318px' };
    background-color: #E6F0FF;
    
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '40px' };
    overflow: hidden;

    position: relative;

    ${opacityAnimation};
`;

const ImgContainer = styled.div`
    ${opacityAnimation};
`;

const Content = ({ title, subCopy, top, isVisible, isSmallScreen }) => {

    return (
        <ContentContainer top={top} isVisible={isVisible} isSmallScreen={isSmallScreen}>
            <Title 
                dangerouslySetInnerHTML={{ __html: title }} 
                isSmallScreen={isSmallScreen}
            />
            <SubCopy 
                dangerouslySetInnerHTML={{ __html: subCopy }} 
                isSmallScreen={isSmallScreen}
            />
        </ContentContainer>
    );

}

const ContentContainer = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '165px' : '380px' };

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '12px' };

    position: absolute;
    top: ${ ({top}) => top };
    left: ${({ isSmallScreen }) => isSmallScreen ? '139px' : '380px' };

    ${opacityAnimation};
`;

const Title = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '13px' : '24px' };
    font-weight: 600;
    color: #2F2F2F;
    lineHeight: 140%;
`;

const SubCopy = styled.p`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '10px' : '18px' };
    color: #2F2F2F;
    text-align: left;
    letter-spacing: -0.27px;
`;