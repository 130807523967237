import { useEffect, useState } from "react";
import styled from "styled-components";
import RadioInput from "./RadioInput";
import FileInput from "./FileInput";
import SubmitButton from "./SubmitButton";
import TextInputSection from "./TextInputSection";
import TextArea from "./TextArea";
import Modal from "./Modal";
import ToastMessage from "./ToastMessage";


const InquiryForm = ({ isSmallScreen }) => {

    const [ formData, setFormData ] = useState({
        inquiryType: 'service',
        company: '',
        name: '',
        email: '',
        contact: '',
        inquiryContent: '',
        file: null,
    });

    const [ formErrors, setFormErrors ] = useState({});
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const [ toastMessage, setToastMessage ] = useState('');
    const [ showToast, setShowToast ] = useState(false);

    const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ]/;
    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    const contactRegex = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;

    useEffect(() => {
        if (showToast) {
            const timer = setTimeout(() => {
                setShowToast(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showToast]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        
        if (isSubmitted) {
            const errors = { ...formErrors };
            
            if (name === 'company') {
                if (!value || koreanRegex.test(value)) {
                    errors.company = true;
                } else {
                    delete errors.company;
                }
            } else if (name === 'name') {
                if (!value || koreanRegex.test(value)) {
                    errors.name = true;
                } else {
                    delete errors.name;
                }
            } else if (name === 'email') {
                if (!emailRegex.test(value)) {
                    errors.email = true;
                } else {
                    delete errors.email;
                }
            } else if (name === 'contact') {
                if (!contactRegex.test(value)) {
                    errors.contact = true;
                } else {
                    delete errors.contact;
                }
            } else if (name === 'inquiryContent') {
                if (!value) {
                    errors.inquiryContent = true;
                } else {
                    delete errors.inquiryContent;
                }
            }
        
            setFormErrors(errors);
        }
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, file: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        const errors = {};
        let toastMessage = '';

        if (!formData.company || koreanRegex.test(formData.company)) {
            errors.company = true;
            toastMessage = !formData.company ? '기업/기관명을 입력해주세요.' : '기업/기관명 형식이 잘못되었습니다.';
        } else if (!formData.name || koreanRegex.test(formData.name)) {
            errors.name = true;
            toastMessage = !formData.name ? '성명을 입력해주세요.' : '성명 형식이 잘못되었습니다.';
        } else if (!formData.email || !emailRegex.test(formData.email)) {
            errors.email = true;
            toastMessage = !formData.email ? '이메일을 입력해주세요.' : '이메일 형식이 잘못되었습니다.';
        } else if (!formData.contact || !contactRegex.test(formData.contact)) {
            errors.contact = true;
            toastMessage = !formData.contact ? '연락처를 입력해주세요.' : '연락처 형식이 잘못되었습니다.';
        } else if (!formData.inquiryContent) {
            errors.inquiryContent = true;
            toastMessage = '문의 내용을 입력해주세요.';
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setToastMessage(toastMessage);
            setShowToast(true);
        } else {
            setFormErrors({});
            setIsModalOpen(true);
            console.log(formData);
        }
    };

    const handleModalClose = (e) => {
        e.preventDefault();
        
        setIsModalOpen(false);
        setFormData({
            inquiryType: 'service',
            company: '',
            name: '',
            email: '',
            contact: '',
            inquiryContent: '',
            file: null,
        });

        setIsSubmitted(false);
    };


    return (
        <Form isSmallScreen={isSmallScreen}>
            <Container isSmallScreen={isSmallScreen}>
                <RadioInput formData={formData} handleChange={handleChange} isSmallScreen={isSmallScreen} />
                <TextInputSection 
                    formData={formData} 
                    handleChange={handleChange} 
                    isSmallScreen={isSmallScreen}
                    formErrors={formErrors}
                    isSubmitted={isSubmitted}
                />
                <TextArea
                    label="문의 내용"
                    type="textarea"
                    name="inquiryContent"
                    placeholder="문의 내용을 입력해주세요."
                    value={formData.inquiryContent}
                    handleChange={handleChange}
                    isSmallScreen={isSmallScreen}
                    hasError={isSubmitted && formErrors.inquiryContent}
                />
                <FileInput handleFileChange={handleFileChange} isSmallScreen={isSmallScreen} />
                <SubmitButton handleSubmit={handleSubmit} isSmallScreen={isSmallScreen} />
                { showToast && <ToastMessage message={toastMessage} isSmallScreen={isSmallScreen} /> }
            </Container>
            
            <Modal 
                    onClose={handleModalClose} 
                    isSmallScreen={isSmallScreen}
                    isModalOpen={isModalOpen}
                />
        </Form>
    );

}

export default InquiryForm;


const Form = styled.form`
    width: ${({ isSmallScreen }) => isSmallScreen ? '100%' : '976px'};
    height: 1100px;
    background-color: ${({ theme }) => theme.colors.gray1};
    padding: 56px 0;
    margin: 0 auto;
    border-radius: ${({ isSmallScreen }) => isSmallScreen ? '' : '20px'};
`;

const Container = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : 'auto'};
    margin: ${({ isSmallScreen }) => isSmallScreen ? '0 auto' : '0 98px'};

    display: flex;
    flex-direction: column;
`;