import { theme } from "../../utils/palette.js";
import Content from "../common/Content.js";
import PartnerCards from "./PartnerCards.js";
import { krMain } from "../../datas/data.js";
import { Section, OpacityAnimation, RefloatAnimation } from "../../styled/common.js";
import useScrollVisible from "../../hooks/useScrollVisible.js";
import { useRef } from "react";
import useScreenSize from "../../hooks/useScreenSize.js";


const Patners = ({ height }) => {

    const { isSmallScreen } = useScreenSize();
    const contentHeight = isSmallScreen ? '70vh' : height;

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0.7 , 850);

    const title = isSmallScreen ? krMain.partner.title.m : krMain.partner.title.pc;
    const subCopy = isSmallScreen ? krMain.partner.subCopy.m : krMain.partner.subCopy.pc;
    const color = theme.gray4;


    return (
        <Section className="flex-col overflow-hidden" height={contentHeight} ref={sectionRef}>
            <RefloatAnimation isVisible={isFristVisible}>
                <Content 
                    title={title}
                    subCopy={subCopy}
                    color={color}
                    isSmallScreen={isSmallScreen}
                />
            </RefloatAnimation>
            <OpacityAnimation isVisible={isSecondVisible}>
                <PartnerCards isSmallScreen={isSmallScreen} />
            </OpacityAnimation>
        </Section>
    );

}

export default Patners;