import { useRef } from "react";
import { krInfo } from "../../datas/data";
import useScreenSize from "../../hooks/useScreenSize";
import useScrollVisible from "../../hooks/useScrollVisible";
import Content from "../common/Content";
import { theme } from "../../utils/palette";
import { OpacityAnimation, RefloatAnimation } from "../../styled/common";
import HistoryTable from "./HistoryTable";


const History = () => {

    const { isSmallScreen } = useScreenSize();

    const sectionRef = useRef(null);
    const threshold = isSmallScreen ? 1 : 0.5;
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, threshold, 850);
    
    const title = isSmallScreen ? krInfo.history.title.m : krInfo.history.title.pc;
    const subCopy = isSmallScreen? krInfo.history.subCopy.m : krInfo.history.subCopy.pc;
    const color = theme.colors.gray4;


    return (
        <section className={`bg-white ${isSmallScreen ? 'py-80' : 'py-200'}`} ref={sectionRef}>
            <RefloatAnimation isVisible={isFristVisible}>
                <Content 
                    title={title}
                    subCopy={subCopy}
                    color={color}
                    isSmallScreen={isSmallScreen}
                />
            </RefloatAnimation>
            <OpacityAnimation isVisible={isSecondVisible}>
                <HistoryTable isSmallScreen={isSmallScreen} />
            </OpacityAnimation>
        </section>
    );

}

export default History;