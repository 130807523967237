import { useRef } from "react";
import { SmallDataValueBg, SmallData, ProductUseSolution, CustomerInsight, CustomerSatisfactionBg, Patners, ServiceInquiry } from "../components";


const MainPage = ({ height, headerHeight }) => {

    const smallDataRef = useRef(null);


    return (
        <div style={{ marginTop : headerHeight }}>
            <SmallDataValueBg height={height} nextSectionRef={smallDataRef} headerHeight={headerHeight} />
            <SmallData height={height} ref={smallDataRef} />
            <ProductUseSolution height={height} />
            <CustomerInsight height={height} />
            <CustomerSatisfactionBg />
            <Patners height={height} />
            <ServiceInquiry />
        </div>
    );

}

export default MainPage;