import React, { useState } from "react";
import { OpacityAnimation, RefloatAnimation, Section } from "../../styled/common";
import useScreenSize from "../../hooks/useScreenSize";
import useScrollVisible from "../../hooks/useScrollVisible";
import { krInfo, missionsData } from "../../datas/data";

import Content from "../common/Content";
import { theme } from "../../utils/palette";
import styled from "styled-components";


const OurMission = React.forwardRef(({ height }, ref) => {

    const { isSmallScreen } = useScreenSize();

    const [ missions ] = useState(missionsData);

    const threshold = isSmallScreen ? 1 : 0.5
    const [ isFirstVisible, isSecondVisible ] = useScrollVisible(ref, threshold, 850);
    
    const title = isSmallScreen ? krInfo.mission.title.m : krInfo.mission.title.pc;
    const subCopy = isSmallScreen? krInfo.mission.subCopy.m : krInfo.mission.subCopy.pc;
    const color = theme.colors.gray4;


    return (
        <Section className="bg-white" height={isSmallScreen ? '600px' : height} ref={ref}>
            <section className={`${isSmallScreen ? 'w-320' : 'w-1180'} mx-auto`}>
                <RefloatAnimation isVisible={isFirstVisible}>
                    <Content
                        title={title}
                        subCopy={subCopy}
                        color={color}
                        isSmallScreen={isSmallScreen}
                    />
                </RefloatAnimation>
                <OpacityAnimation 
                    className={`flex ${isSmallScreen ? 'flex-col gap-y-10' : 'flex-row gap-x-20'}`} 
                    isVisible={isSecondVisible}>
                {
                    missions.map((mission, i) => (
                        <Container isSmallScreen={isSmallScreen} key={i}>
                            <Subject isSmallScreen={isSmallScreen}>
                                <div className={`${isSmallScreen ? 'w-35 h-35' : 'w-70 h-70'}`}>
                                    <img src={mission.img} />
                                </div>
                                <Mission isSmallScreen={isSmallScreen}>
                                    { mission.subject }
                                </Mission>
                            </Subject>
                            <P isSmallScreen={isSmallScreen}>
                                { mission.content }
                            </P>
                        </Container>
                    ))
                }
                </OpacityAnimation>
            </section>
        </Section>
    );

})

export default OurMission;


const Container = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen ? '320px' : '380px'};
    height: ${({ isSmallScreen }) => isSmallScreen ? '100px' : '250px'};
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ isSmallScreen }) => isSmallScreen ? '21px 12px 22px 12px' : '32px 30px 40px 30px' };
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.10);

    border-radius: 20px;

    display: flex;
    flex-direction: ${({ isSmallScreen }) => isSmallScreen ? 'row' : 'column'};
    justify-content: center;
    align-items: center;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '15px' : '8px'};
`;

const Subject = styled.div`
    width: ${({ isSmallScreen }) => isSmallScreen && '61px'};
    height: ${({ isSmallScreen }) => isSmallScreen && '57px'};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '9px' : '18px'};
`;

const Mission = styled.h1`
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '24px'};
    font-weight: 600;
`;

const P = styled.p`
    width: ${({ isSmallScreen }) => isSmallScreen ? '220px' : '320px'};
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '14px' : '16px'};
    text-align: ${({ isSmallScreen }) => isSmallScreen ? 'left' : 'center'};
    line-height: 160%;
    letter-spacing: ${({ isSmallScreen }) => isSmallScreen ? '-0.28px' : '-0.24px'};
`;