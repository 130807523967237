import styled, { keyframes } from 'styled-components';
import chevronDown from '../../img/icon/chevron_down.svg';
import { refloatAnimation } from '../../styled/common';


const ScrollYButton = ({ onClick, isVisible }) => {

    return (
        <div className="absolute bottom-6">
            <ChevronButton onClick={onClick} isVisible={isVisible}>
                <img className="block w-44 h-70" src={chevronDown} />
            </ChevronButton>
        </div>
    );

}

export default ScrollYButton;



const bounce = keyframes`
    0% {
        transform: translateY(-25px);
        opacity: 1;
    }
    25%{
        transform: translateY(0px);
        opacity: 1;
    }
    40% {
        transform: translateY(-15px);
        opacity: 1;
    }
    60% {
        transform: translateY(0px);
        opacity: 1;
    }
    90%, 100% {
        transform: translateY(-25px);
        opacity: 0;
    }
`;

const ChevronButton = styled.button`
    width: 50px;
    height: 50px;
    margin-bottom: 7px;

    ${refloatAnimation};
    animation: ${bounce} 4s infinite;
`;