import styled from "styled-components";
import bgImage from '../../img/info_main_bg.png';
import useScreenSize from "../../hooks/useScreenSize";
import useScrollVisible from "../../hooks/useScrollVisible";
import { refloat, Section } from "../../styled/common";
import ScrollYButton from "../common/ScrollYButton";
import { scrollToSection } from "../../utils/functions";
import { krInfo, krMain } from "../../datas/data";
import { useRef } from "react";


const InfoMainBg = ({ height, nextSectionRef, headerHeight }) => {

    const { isSmallScreen } = useScreenSize();

    const sectionRef = useRef(null);
    const [ isFristVisible, isSecondVisible ] = useScrollVisible(sectionRef, 0, 850);

    const handleScroll = () => { scrollToSection(nextSectionRef, headerHeight); };

    const title = isSmallScreen ? krInfo.main.title.m : krInfo.main.title.pc;
    const subCopy = isSmallScreen ? krInfo.main.subCopy.m : krInfo.main.subCopy.pc;
    const textBoxMargin = isSmallScreen ? '125px auto 0 auto' : '35vh auto 0 auto';


    return (
        <Section ref={sectionRef}>
            <BgCrop height={height} isSmallScreen={isSmallScreen}>
                <img src={bgImage} className="block w-full h-full object-cover"/>
                <TextBox margin={textBoxMargin} isVisible={isFristVisible}>
                    <H1 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: title }} 
                    />
                    <P 
                        isSmallScreen={isSmallScreen}
                        dangerouslySetInnerHTML={{ __html: subCopy }} 
                    />
                </TextBox>
                { isSmallScreen || <ScrollYButton onClick={handleScroll} isVisible={isSecondVisible} /> }
            </BgCrop>
        </Section>
    );

}

export default InfoMainBg;


const BgCrop = styled.div`
    width: 100%;
    height: ${({ isSmallScreen, height }) => isSmallScreen ? '420px' : height };

    display: flex;
    justify-content: center;
    
    position: relative;
    overflow: hidden;
`;

const TextBox = styled.div`
    color: #FFFFFF;
    text-align: center;
    white-space: nowrap;

    margin: ${ ({margin}) => margin };
    
    position: absolute;

    opacity: ${ ({isVisible} ) => isVisible ? 1 : 0 };
    visibility: ${ ({isVisible} ) => isVisible ? 'visible' : 'hidden' };
    animation: ${ ({isVisible} ) => isVisible ? refloat : 'none' } 0.7s ease-in-out forwards;
`;

const H1 = styled.h1`
        font-size: ${ ({isSmallScreen}) => isSmallScreen ? '26px' : '40px' };
        font-weight: 700;
        line-height: 140%;

        margin-bottom: ${ ({isSmallScreen}) => isSmallScreen ? '8px' : '18px' };
`;

const P = styled.p`
        font-size: ${ ({isSmallScreen}) => isSmallScreen ? '16px' : '18px' };
        line-height: 140%;
        letter-spacing: -0.24px;
`;