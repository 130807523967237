import React from "react";
import styled from "styled-components";
import usePreventScroll from "../../hooks/usePreventScroll";
import { visibilityStyles } from "../../styled/common";


const Modal = ({ onClose, isSmallScreen, isModalOpen }) => {

    usePreventScroll(isModalOpen);


    return (
        <Overlay onClick={onClose} isVisible={isModalOpen}>
            <ModalContainer 
                onClick={(e) => e.stopPropagation()} 
                isSmallScreen={isSmallScreen}
                >
                <Message isSmallScreen={isSmallScreen}>
                    정상적으로 전송되었습니다.
                </Message>
                <Button 
                    onClick={onClose} 
                    isSmallScreen={isSmallScreen}
                    >
                        확인
                </Button>
            </ModalContainer>
        </Overlay>
    );
};

export default Modal;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    ${visibilityStyles};
`;

const ModalContainer = styled.div`
    background-color: white;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '15px 20px 10px 20px' : '20px 36px 15px 36px'};
    border-radius: 8px;
    text-align: center;
`;

const Message = styled.p`
    font-size: 18px;
    margin-bottom: 16px;
`;

const Button = styled.button`
    background-color: ${({ theme }) => theme.colors.main1};
    color: white;
    padding: ${({ isSmallScreen }) => isSmallScreen ? '4px 8px' : '4px 16px'};
    border: none;
    border-radius: 4px;

    &:hover {
        opacity: 0.8;
    }
`;
