import styled from "styled-components";
import useScreenSize from "../../hooks/useScreenSize";
import { theme } from "../../utils/palette";
import Content from "../common/Content";
import { useEffect, useState } from "react";
import { googleMapsApiKey } from "../../datas/googleMapsApiKey";
import { GoogleMap, InfoWindowF, LoadScriptNext, MarkerF,  } from "@react-google-maps/api";
import { locationData } from "../../datas/data";


const Directions = () => {

    const { isSmallScreen, isXLargeScreen } = useScreenSize();
    const [ directions ] = useState(locationData);
    const [ selectedPlace, setSelectedPlace ] = useState(directions[0].coords);
    const [ selectedIcon, setSelectedIcon ] = useState(isSmallScreen ? directions[0].icon.small : directions[0].icon.large);
    const [ isClicked, setIsClicked ] = useState(directions[0].name);
    const [ mapLoaded, setMapLoaded ] = useState(false);
    const [ isInfoWindowOpen, setIsInfoWindowOpen ] = useState(false);

    useEffect(() => {
        if (window.google && window.google.maps) {
          setMapLoaded(true);
        } else {
          console.error('Google Maps API is not loaded');
        }
      }, []);

    const handleButtonClick = (direction) => {
        setSelectedPlace(direction.coords);
        setSelectedIcon(isSmallScreen ? direction.icon.small : direction.icon.large);
        setIsClicked(direction.name);
        setIsInfoWindowOpen(false);
    };

    const handleMarkerClick = () => {
        setIsInfoWindowOpen(true);
    };

    const handleInfoWindowCloseClick = () => {
        setIsInfoWindowOpen(false);
    };

    const mapContainerStyle = {
        width: '100%',
        height: isXLargeScreen ? '800px' : isSmallScreen ? '360px' : '635px'
    };

    const mapOptions = {
        disableDefaultUI: true,
        zoom: 15,
        center: selectedPlace,
        styles: [
            { featureType: "poi", elementType: "labels", stylers: [{ visibility: "off" }] }
        ]
    };


    return (
        <section className={`relative ${isSmallScreen ? 'pt-80' : 'pt-200'}`}>
            <Content 
                title="찾아오시는 길"
                color={theme.colors.gray4}
                isSmallScreen={isSmallScreen}
            />
            <div className={`flex ${isSmallScreen ? 'gap-x-8 justify-center mb-16' : 'gap-x-20 ml-50 mb-20'}`}>
            {
                directions.map((direction, index) => (
                    <Button
                        key={index}
                        isSmallScreen={isSmallScreen}
                        isClicked={isClicked === direction.name}
                        onClick={() => handleButtonClick(direction)}
                    >
                        { direction.name }
                    </Button>
                ))
            }
            </div>
            <MapContainer isXLargeScreen={isXLargeScreen}>
                <LoadScriptNext googleMapsApiKey={googleMapsApiKey}>
                    <GoogleMap
                        mapContainerStyle={mapContainerStyle}
                        options={mapOptions}
                    >
                    <MarkerF 
                        position={selectedPlace} 
                        onClick={handleMarkerClick}
                        icon={{ url: selectedIcon }}
                    />
                    {
                        isInfoWindowOpen && (
                            <InfoWindowF
                                position={selectedPlace}
                                onCloseClick={handleInfoWindowCloseClick}
                            >
                                <Badge isSmallScreen={isSmallScreen}>
                                {
                                    ['주소', '우편번호', '연락처'].map((label, index) => {
                                        const key = index === 0 ? 'address' : index === 1 ? 'postCode' : 'tel';
                                        return (
                                            <div className="flex" key={key}>
                                                <span className="min-w-32">{label} :&nbsp;</span>
                                                <span className={`${isSmallScreen ? 'text-13' : 'text-15'} font-bold`}>
                                                    { directions.find(direction => direction.coords === selectedPlace)?.[key] }
                                                </span>
                                            </div>
                                        );
                                    })
                                }
                                </Badge>
                            </InfoWindowF>
                        )
                    }
                    </GoogleMap>
                </LoadScriptNext>
            </MapContainer>
        </section>
    );

}

export default Directions;


const Button = styled.button`
    height: 24px;
    background-color: ${({ theme }) => theme.colors.white};
    padding: 12px 20px;
    border: ${({ theme, isClicked }) => isClicked ? 
            `1.5px solid ${theme.colors.main1}` : `1px solid ${theme.colors.gray6}`};
    border-radius: 12px;
    box-sizing: content-box;

    font-size: ${({ isSmallScreen }) => isSmallScreen ? '16px' : '20px'};
    font-weight: ${({ isClicked }) => isClicked ? '600' : '400'};
    color: ${({ theme, isClicked }) => isClicked ? theme.colors.gray3 : theme.colors.gray8};

    display: inline-flex;
    justify-content: center;
    align-items: center;

    ${({ isClicked }) => !isClicked && `
        &:hover {
            background-color: rgba(235, 235, 235, 0.2);
        }
    `}
`;

const MapContainer = styled.div`
    width: 100%;
`;

const Badge = styled.div`
    background-color: ${({ theme }) => theme.colors.accent3};
    color: ${({ theme }) => theme.colors.white};
    padding: 8px;
    border-radius: 8px;
    font-size: ${({ isSmallScreen }) => isSmallScreen ? '12px' : '14px'};
    font-weight: 600;

    display: flex;
    flex-direction: column;
    gap: ${({ isSmallScreen }) => isSmallScreen ? '4px' : '6px'};
`;